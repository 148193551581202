<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :loading="loadingTeams"
        :dataSource="teamTableData"
        :columns="teamTableColumns"
        :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
        @change="paginationChange"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { TableStyleWrapper } from '../../user/style';
import { TableWrapper } from '../../styled';
import { computed, defineComponent } from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useRoute, useRouter} from "vue-router";
import {useAbility} from "@casl/vue";

const teamTableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Omschrijving',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Aangemaakt op',
    dataIndex: 'createTimestamp',
    key: 'createTimestamp',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const TeamListTable = defineComponent({
  name: 'TeamListTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const teams = computed(() => state.team.teams);
    const loadingTeams = computed(() => state.team.loadingTeams);
    const currentPage = computed(() => state.team.currentPage);
    const count = computed(() => state.team.count);
    const router = useRouter();
    const {matched} = useRoute();
    const {path} = matched[1];
    const { can } = useAbility();
    const handleDelete = async (id) => {
      await dispatch('deleteTeam', id);
      await dispatch('getTeams', {page: state.team.currentPage, pageSize: state.team.pageSize});
    };
    const handleEdit = (id) => {
      router.push(`${path}/${id}`);
    };
    const teamTableData = computed(() =>
        teams.value.map((team) => {
        const { id, name, description, createTimestamp } = team;

        return {
          key: id,
          id: id,
          name: (
            <div class="user-info">
              <figure>
                  <img style={{ width: '40px' }} src={require('@/static/img/users/1.png')} alt="" />
              </figure>
              <figcaption>
                <sdHeading class="user-name" as="h6">
                  {name}
                </sdHeading>
              </figcaption>
            </div>
          ),
          description: <span>{description}</span>,
          createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
          action: (
              <div className="table-actions">
                {can('view', 'team') ? (
                    <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
                      <sdFeatherIcons type="edit" size="16"/>
                    </sdButton>
                ) : ('')}
                {can('delete', 'team') ? (
                    <a-popconfirm
                        title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
                        ok-text="Ja"
                        cancel-text="Nee"
                        placement="leftTop"
                        onConfirm={() => handleDelete(id)}
                    >
                      <sdButton className="btn-icon" type="default" to="#" shape="circle">
                        <sdFeatherIcons type="trash-2" size="16"/>
                      </sdButton>
                    </a-popconfirm>
                ) : ('')}
              </div>
          ),
        };
      }),
    );

    const rowSelection = {
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled team', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const paginationChange = async (event) => {
      await dispatch('setCurrentPageTeams', event.current);
      await dispatch('getTeams', {page: event.current, pageSize: state.team.pageSize});
    }

    return { teamTableColumns, teamTableData, rowSelection, loadingTeams, paginationChange, currentPage, count };
  },
});

export default TeamListTable;
</script>
