<template>
  <sdDrawer
      ref="TeamAddRef"
      :form="form"
      title="Team aanmaken"
      type="submit"
      btnText="Toevoegen"
      submitBtnText="Opslaan"
  >
    <FormValidationWrap>
      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateTeam">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item :label="locale.page.teamAdd.form.name" name="name">
              <a-input v-model:value="form.name" placeholder=""/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item :label="locale.page.teamAdd.form.description" name="description">
              <a-textarea
                  v-model:value="form.description"
                  placeholder=""
                  class="sDash_unresizable"
                  :rows="3"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button size="large" class="btn-signin" type="primary" html-type="submit" :loading="loadingCreateTeam"
                    :disabled="loadingCreateTeam"> {{ locale.page.teamAdd.form.btnSave }}
          </a-button>
        </div>
      </a-form>
    </FormValidationWrap>
  </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import locale from "@/static/locale/nl.json";
import {useStore} from "vuex";

const TeamAdd = defineComponent({
  name: 'TeamAdd',
  components: {
    FormValidationWrap,
  },
  setup() {
    const TeamAddRef = ref()
    const formRef = ref();
    const {state, dispatch} = useStore();
    const loadingCreateTeam = computed(() => state.team.loadingCreateTeam);
    const form = reactive({
      name: null,
      description: '',
    });
    const rules = {
      name: [
        {
          required: true,
          message: locale.validation.required,
        },
      ],
      description: [
        {
          required: true,
          message: locale.validation.required,
        },
      ],
    };
    const closeDrawer = () => {
      TeamAddRef.value.onClose();
      formRef.value.resetFields();
      dispatch('getTeams');
    }
    const CreateTeam = () => {
      dispatch('createTeam', {value:form,close:closeDrawer});
    };
    return {
      form,
      rules,
      locale,
      CreateTeam,
      loadingCreateTeam,
      TeamAddRef,
      formRef,
    };
  },
});

export default TeamAdd;
</script>
